/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/01/2021.
 */
import React, { useRef } from 'react'
import sucessIcon from '../assets/sucess-icon.svg'
import { useHover } from 'react-use-gesture'
import { gsap } from 'gsap'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const thanks = (props) => {
  const arrowRef = useRef()
  const bind = useHover((hoverState) => {
    const to = hoverState.active ? 8 : 0
    gsap.to(arrowRef.current, 0.3, { x: to, ease: 'expo.out' })
  })

  // state will be defined if sent from contact form.
  const locState = props.location.state
  // console.log(' thanks > state = ', locState)

  return (
    <Layout>
      <div className="container-fluid h-100 mt-6">
        <div className="row " style={{ minHeight: '60vh' }}>
          <div className="col-12 col-md-8 mx-auto d-flex flex-column justify-content-center align-items-center">
            <img className="mb-4" src={sucessIcon} alt="" />

            <h4 className="text-center mb-4 text-primary">We have received your request</h4>

            <p className="text-center">Thanks for contacting us. We will reply to your request as soon as possible.</p>

            <div {...bind()} className="mt-2 mt-md-2 text-center">
              <Link to={'/'}>
                <button
                  className="text-primary font-weight-bold"
                  style={{ border: 'none', background: 'none' }}
                  type="submit"
                >
                  <u>Back to frontpage</u>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default thanks
